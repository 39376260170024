#whatsapp .wtsapp{
    position: fixed;
    transform: all .5s ease;
    /* background-color: #25d366; */
    display: block;
    text-align: center;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    border-radius: 50px;
    border-right: none;
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    bottom: 30px;
    left: 30px;
    border: 0;
    z-index: 9999;
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
  #whatsapp .wtsapp:before{
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
  
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation:pulse-border 1500ms ease-out infinite;
  }
  #whatsapp .wtsapp:focus{
    border: none;
    outline: none;
  
  }
  
  @keyframes pulse-border{
    0%{transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1); opacity: 1;}
    100%{transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1.5); opacity: 0;}
  }


  
  
  body::-webkit-scrollbar-track{
    -webkit-box-shadow: inset aqua;
  background-color: #F5F5F5;
  border-radius: 10px;
  }
  body::-webkit-scrollbar{
    width: 10px;
  background-color: #F5F5F5;
  }

  body::-webkit-scrollbar-thumb{
    border-radius: 10px;
  background-color: #FFF;
  background-image: -webkit-gradient(linear,
                     40% 0%,
                     75% 84%,
                     from(aqua),
                     to(aqua),
                     color-stop(.6,aqua))
  }